// @ts-nocheck we are going to come back and check each in-use file
/* global CedarChat */
(function () {
    const serverDataGlobal = cedar.serverDataGlobal;
    const providerName = _.unescape(serverDataGlobal.provider_name);
    const providerWhitelabelColor = serverDataGlobal.provider_whitelabel_color;
    const phoneNumber = serverDataGlobal.formatted_phone_call_inbound;
    const lang = serverDataGlobal.current_language;
    const enable_http_endpoint_thread_join_patient =
        serverDataGlobal.enable_http_endpoint_thread_join_patient;
    const enable_http_endpoint_thread_leave_patient =
        serverDataGlobal.enable_http_endpoint_thread_leave_patient;
    const enable_http_endpoint_view_chat_patient =
        serverDataGlobal.enable_http_endpoint_view_chat_patient;
    const enable_http_endpoint_loaded_history_patient =
        serverDataGlobal.enable_http_endpoint_loaded_history_patient;
    const enable_http_endpoint_submit_chat_patient =
        serverDataGlobal.enable_http_endpoint_submit_chat_patient;
    const enable_http_endpoint_user_typing_patient =
        serverDataGlobal.enable_http_endpoint_user_typing_patient;

    function openChat() {
        CedarChat.open();
    }

    CedarChat.on("open", function () {
        CedarChat.opening_element = document.activeElement;
        $("._nav_contact_us").addClass("hide");
        $("._navbar_top_divider").addClass("hide");
        $("#content").addClass("chat__main-container");
        $("#content").removeClass("chat__main-exit").addClass("chat__main-enter");
        $("#navbar-content").removeClass("chat__main-exit").addClass("chat__main-enter");

        $("#content").addClass("chat__open-width"); // Controls page width
        $("#navbar-content").addClass("chat__open-width");
        addEventListener("keydown", handleKeyDown);
    });

    function handleKeyDown(e) {
        if (e.key === "Escape" || e.key === "Esc" || e.keyCode == 27) {
            // "Esc" is the key that IE/Edge and some versions of firefox returns, while other browsers return "Escape"
            CedarChat.close();
        }

        const isTabPressed = e.key === "Tab" || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        const focusableElements =
            'textarea:not([disabled]), button:not([disabled]), a, input[type="checkbox"]:not([disabled])';
        const modal = document.querySelector("#cedar-chat");
        const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
        const focusableContent = modal.querySelectorAll(focusableElements);
        const lastFocusableElement = focusableContent[focusableContent.length - 1];

        if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                e.preventDefault();
            }
        }
    }

    CedarChat.on("close", function () {
        // Animations before the containers are removed
        $("#content").removeClass("chat__main-enter").addClass("chat__main-exit");
        $("#navbar-content").removeClass("chat__main-enter").addClass("chat__main-exit");

        // Reset page content flow and width immediately which is inverse of animation above
        $("#content").removeClass("chat__open-width");
        $("#navbar-content").removeClass("chat__open-width");

        // Display Live Chat button when chat is closed
        $("._nav_contact_us").removeClass("hide");
        $("._navbar_top_divider").removeClass("hide");

        setTimeout(function () {
            CedarChat.opening_element.focus();
            // Removes the class which controls the flow of the page only after the animation
            // has ended and the page is at the full width
            $("#content").removeClass("chat__main-container");
            removeEventListener("keydown", handleKeyDown);
        }, 500);
    });
    CedarChat.init({
        chatDomain: window.location.host, // Domain of provider site. i.e. pay.crystalrunhealthcare.com. The provider must have chat_domains field set.
        websocketUrl: serverDataGlobal.websocket_url,
        headerText: providerName, // Any text to show at the top of chat
        primaryColor: providerWhitelabelColor, // Color of the widget
        showLauncher: false,
        windowRight: true,
        phoneNumber: phoneNumber,
        lang: lang,
        csrfToken: serverDataGlobal.csrf_token,
        enable_http_endpoint_thread_join_patient: enable_http_endpoint_thread_join_patient,
        enable_http_endpoint_thread_leave_patient: enable_http_endpoint_thread_leave_patient,
        enable_http_endpoint_view_chat_patient: enable_http_endpoint_view_chat_patient,
        enable_http_endpoint_loaded_history_patient: enable_http_endpoint_loaded_history_patient,
        enable_http_endpoint_submit_chat_patient: enable_http_endpoint_submit_chat_patient,
        enable_http_endpoint_user_typing_patient: enable_http_endpoint_user_typing_patient,
    });

    // If chat is in the query string, open the chat immediately
    if (cedar.parseQuery(document.location.search).chat) {
        CedarChat.open();
    }

    const helpElements = Array.prototype.slice.call(
        document.getElementsByClassName("_get_help_btn"),
    );
    helpElements.forEach(function (el) {
        el.addEventListener("click", openChat);
        el.addEventListener("keydown", (el) => {
            if (el.key === "Enter") openChat();
        });
    });
})();

export {};
